import * as api from '../api/userApi';
import { USER_INFO_STORE, CALL_TO_USER_INFO_PAGE,GET_USER_INFO } from '../api/constant';

export const addUserInfo = (userInfo) => async (dispatch) => {
    try {
        const response = await api.addUserInfo(userInfo);
        console.log(response);
        await dispatch({ type: USER_INFO_STORE, data: response.data });
    } catch (error) {
        console.log(error);
    }
};
export const userInfoPageCall = (userInfoPageCallPaylod) => async (dispatch) => {
    try {
        dispatch({
            type: CALL_TO_USER_INFO_PAGE,
            payload: userInfoPageCallPaylod
        });
    } catch (error) {
        console.log(error);
    }
};

export const getUserInfo = (id) => async (dispatch) => {
    try {
        const { data } = await api.getUserInfo(id)
        dispatch({ type: GET_USER_INFO, payload: data });
    } catch (error) {
        console.log(error)
    }
}