import indices from './indices';
import beforeIndices from './before-indices';
import afterIndices from './after-indices';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [indices, afterIndices]
};

export default menuItems;
