import logo from 'assets/images/hs-logo.png';
import favicon from 'assets/images/favicon.ico';

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    return (<>
        <img src={favicon} alt="logo" />
        <img src={logo} alt="HoppySearch" width="180" />
    </>);
};

export default Logo;
