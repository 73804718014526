import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { pageInfo } from './info';
function PrivacyPolicyInfo() {
    return (
        <Box
            sx={{
                width: '90%',
                margin: 'auto',
                backgroundColor: '#f9f9ff',
                p: 4,
                borderRadius: '3px',
                mb: 4
            }}
        >
            {pageInfo?.map((information, index) => (
                <Grid container spacing={gridSpacing} mb={5}>
                    <Grid item xs={12}>
                        <Typography
                            textAlign="left"
                            sx={{
                                fontSize: { sm: '1rem' },
                                fontFamily: 'Verdana, Arial, Helvetica, sans-serif',
                                fontWeight: 'bold',
                                color: '#7c859b',
                                mb: 3
                            }}
                        >
                            {information?.title}
                        </Typography>
                        {information?.info?.map((item, ind) => (
                            <Typography
                                sx={{
                                    fontSize: { sm: '1.0rem' },
                                    fontFamily: 'Verdana, Arial, Helvetica, sans-serif',
                                    color: '#7c859b',
                                    mb: 3
                                }}
                            >
                                {item?.description}
                            </Typography>
                        ))}
                    </Grid>
                   
                </Grid>
            ))}
        </Box>
    );
}
export default PrivacyPolicyInfo;
