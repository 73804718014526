// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Box, Container, Grid, Link, Stack, Typography } from '@mui/material'; // Divider

// project import
import Chip from 'ui-component/extended/Chip';
import { frameworks } from './FrameworkSection';

// assets
// import Dribble from 'assets/images/landing/footer-dribble.png';
// import Freepik from 'assets/images/landing/footer-freepik.png';
// import Awards from 'assets/images/landing/footer-awards.png';

// Link - custom style
const FooterLink = styled(Link)(({ theme }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.text.secondary : theme.palette.text.hint,
    '&:hover': {
        color: theme.palette.primary.main
    },
    '&:active': {
        color: theme.palette.primary.main
    }
}));

// =============================|| LANDING - FOOTER SECTION ||============================= //

const FooterSection = () => {
    const theme = useTheme();
    const textColor = theme.palette.mode === 'dark' ? 'text.secondary' : 'text.hint';

    // const dividerSX = {
    //     borderImageSlice: 1,
    //     borderImageSource: `linear-gradient(90deg, rgba(255, 255, 255, 0) -0.01%, rgba(255, 255, 255, 0.56) 51.97%, rgba(255, 255, 255, 0.03) 99.99%)`,
    //     opacity: 0.5
    // };

    return (
        <>
            <Container sx={{ mb: 15 }}>
                <Grid container spacing={6}>
                    {/* <Grid item xs={12}>
                        <Stack spacing={4.25}>
                            <Divider sx={dividerSX} />
                            <Stack
                                direction="row"
                                justifyContent="center"
                                spacing={{ xs: 1.5, sm: 6, md: 10, lg: 12 }}
                                sx={{ overflow: 'hidden' }}
                            >
                                <img src={Dribble} alt="dribble" />
                                <img src={Freepik} alt="freepik" />
                                <img src={Awards} alt="awards" />
                            </Stack>
                            <Divider sx={dividerSX} />
                        </Stack>
                    </Grid> */}
                    <Grid item xs={12}>
                        <Grid container spacing={8}>
                            <Grid item xs={12} md={4}>
                                <Stack spacing={{ xs: 2, md: 5 }}>
                                    <Typography variant="h4" color={textColor} sx={{ fontWeight: 500 }}>
                                        About HoppySearch
                                    </Typography>
                                    <Typography variant="body2" color={textColor}>
                                        {`Lucene-powered speed: search-as-you-type in under 100ms. Effortlessly integrate 
                                        advanced search with our API for rapid, accurate results. Scalable and 
                                        cost-effective, enabling efficient search experiences.`}
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Grid container spacing={{ xs: 5, md: 2 }}>
                                    <Grid item xs={6} sm={3}></Grid>
                                    <Grid item xs={6} sm={3}>
                                        <Stack spacing={{ xs: 3, md: 5 }}>
                                            <Typography
                                                variant="h4"
                                                color={textColor}
                                                sx={{ fontWeight: 500, cursor: 'pointer' }}
                                                onClick={() => window.$zoho.salesiq.floatwindow.visible("show")}
                                            >Help
                                            </Typography>
                                            <Stack spacing={{ xs: 1.5, md: 2.5 }}>
                                                <FooterLink href="https://hoppysearch.readme.io/docs/home" target="_blank" underline="none">
                                                    Documentation
                                                </FooterLink>

                                                <FooterLink href="/terms" underline="none">
                                                    Refund Policy
                                                </FooterLink>
                                            </Stack>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <Stack spacing={{ xs: 3, md: 5 }}>
                                            <Typography variant="h4" color={textColor} sx={{ fontWeight: 500 }}>
                                                HoppySearch Eco-System
                                            </Typography>
                                            <Stack spacing={{ xs: 1.5, md: 2.5 }}>
                                                <FooterLink href="https://www.npmjs.com/package/hs-autocomplete-react" target="_blank" underline="none">
                                                    AutoComplete
                                                </FooterLink>
                                                {frameworks.map((item, index) => (
                                                    <FooterLink href={item.link} target="_blank" underline="none" key={index}>
                                                        {item.title}
                                                        {item.isUpcoming && (
                                                            <Chip variant="outlined" size="small" label="Upcoming" sx={{ ml: 0.5 }} />
                                                        )}
                                                    </FooterLink>
                                                ))}
                                            </Stack>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <Stack spacing={{ xs: 3, md: 5 }}>
                                            <Typography variant="h4" color={textColor} sx={{ fontWeight: 500 }}>
                                                Use Cases
                                            </Typography>
                                            <Stack spacing={{ xs: 1.5, md: 2.5 }}>
                                                <FooterLink underline="none">
                                                    Retail
                                                </FooterLink>
                                                <FooterLink underline="none">
                                                    E-Commerce
                                                </FooterLink>
                                                <FooterLink underline="none">
                                                    Marketplace
                                                </FooterLink>
                                                <FooterLink underline="none">
                                                    AutoComplete
                                                </FooterLink>
                                                <FooterLink underline="none">
                                                    Faceted Search
                                                </FooterLink>
                                            </Stack>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            <Box sx={{ bgcolor: 'dark.dark', py: { xs: 3, sm: 1.5 } }}>
                <Container>
                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Stack direction="row" alignItems="center" spacing={{ xs: 1.5, sm: 1, md: 3 }}>
                            <FooterLink href="/terms" underline="none">
                                Terms and Condition
                            </FooterLink>
                            <FooterLink href="/privacy" underline="none">
                                Privacy Policy
                            </FooterLink>
                            <FooterLink href="/data-processing-agreement" underline="none">
                                Data Processing Agreement
                            </FooterLink>
                        </Stack>

                        <Stack direction="row" alignItems="center" spacing={{ xs: 3, sm: 1.5, md: 2 }}>
                            <Typography color="text.secondary">
                                © HoppySearch
                            </Typography>
                        </Stack>
                    </Stack>
                </Container>
            </Box>
        </>
    );
};

export default FooterSection;
