import React from 'react';
import { Container, Stack, Typography } from '@mui/material';
import PrivacyPolicyInfo from './PrivacyPolicyInfo';

function PrivacyPolicy() {
    return (
        <>
            <Container sx={{ my: 6, width: '100%', mb: 6 }}>
                <Stack spacing={0.25} alignItems="center">
                    <Typography
                        align="center"
                        sx={{
                            fontSize: { xs: '1.5rem', sm: '2.6rem' },
                            fontFamily: 'Verdana, Arial, Helvetica, sans-serif',
                            mb: 1
                        }}
                    >
                        Privacy Policy
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: { sm: '1.0rem' },
                            fontFamily: 'Verdana, Arial, Helvetica, sans-serif',
                            color: '#7c859b'
                        }}
                    >
                        Owner and Data Controller
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: { sm: '1.0rem' },
                            fontFamily: 'Verdana, Arial, Helvetica, sans-serif',
                            color: '#7c859b'
                        }}
                    >
                        Hoppysoft Private Limited. – Suite 1001, Plot No 59, Sector 19, Airoli, Navi Mumbai MH 400708 (India).
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: { sm: '1.0rem' },
                            fontFamily: 'Verdana, Arial, Helvetica, sans-serif',
                            color: '#7c859b',
                            mb: 3
                        }}
                    >
                        Owner contact email: contact@hoppysoft.com
                    </Typography>
                </Stack>
            </Container>
            <PrivacyPolicyInfo />
        </>
    );
}
export default PrivacyPolicy;
