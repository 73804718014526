// material-ui
import { Container, Stack, Typography } from '@mui/material';

import PagesPrice1 from 'views/pages/pricing/Price1'
import PriceComparison from '../landing/PriceComparison.js';

// =============================|| LANDING - FEATURE PAGE ||============================= //

const PricingSection = () => {
    return (
        <>
            <Container sx={{ my: 6 }}>
                <Stack spacing={0.25} alignItems="center">
                    <Typography variant="h2" align="center" sx={{ fontSize: { xs: '1.5rem', sm: '2.125rem' } }}>
                        Pricing
                    </Typography>
                    <PagesPrice1 />
                </Stack>
            </Container>
            <Container sx={{ my: 6 }}>
                <Stack spacing={0.25} alignItems="center">
                    <Typography variant="h2" align="center" sx={{ fontSize: { xs: '1.5rem', sm: '2.125rem' } }}>
                        Cutting Costs, Not Corners: The Power of Low-Cost Solutions
                    </Typography>
                </Stack>
            </Container>
            <Container>
                <PriceComparison />
            </Container>
        </>
    );
};

export default PricingSection;
