import { GET_USER_BILLS, ADD_USER_BILL } from '../api/constant';

const userBillInfo = (state = { postBills: null, getBills: null }, action) => {
    switch (action.type) {
        case ADD_USER_BILL:
            return { ...state, postBills: action.data };
        case GET_USER_BILLS:
            return { ...state, getBills: action?.payload?.userBill };
        default:
            return state;
    }
};
export default userBillInfo;
