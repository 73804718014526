// material-ui
import { Grid, LinearProgress, Slider, Typography } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';

// react import
import { useEffect, useState } from 'react';


const plans = [
    {
        title: "HoppySearch",
        standardTitle: 'Standard',
        standardDescription: '',
        standardPrice: 0.3,
        standardPrice2: 0.2,
        standardPrice3: 0.15,
        plusTitle: 'Plus',
        plusDescription: '',
        plusPrice: 0.7,
        plusPrice2: 0.4,
        plusPrice3: 0.2,
        standardPriceAccordingToRequestCount: 0.3,
        plusPriceAccordingToRequestCount: 0.7
    },
    {
        title: "Algolia",
        standardTitle: 'Standard',
        standardDescription: '',
        standardPrice: 0.5,
        standardPrice2: 0.5,
        standardPrice3: 0.5,
        plusTitle: 'Premium',
        plusDescription: '',
        plusPrice: 1.5,
        plusPrice2: 1.5,
        plusPrice3: 1.5,
        standardPriceAccordingToRequestCount: 1,
        plusPriceAccordingToRequestCount: 1.5
    }
];
// ================|| PRICING - PRICE Comparison(Hoppysearch vs Algolia) ||==================== //

const PriceComparison = () => {

    // useState
    const [numberOfSeraches, setNumberOfSeraches] = useState(200000);
    const [hoppysearchTotalPrice, setHoppysearchTotalPrice] = useState({
        standardPrice: 0.3,
        plusPrice: 0.7
    });
    const [algoliaTotalPrice, setAlgoliaTotalPrice] = useState({
        standardPrice: 0.5,
        plusPrice: 1.5
    });
    const [swiftypeTotalPrice, setSwiftypeTotalPrice] = useState({
        standardPrice: 79,
        proPrice: 119
    });
    const [sitesearch360TotalPrice, setSitesearch360TotalPrice] = useState({
        batmanPrice: 524
    });

    // functions
    const handleSearchSlider = (event) => {
        setNumberOfSeraches(event.target.value);
    }

    const hoppySearchPriceCalculator = () => {
        let searchCount = numberOfSeraches;
        let standPrice = 0;
        let plusPrice = 0;

        if (searchCount >= 160000) {
            standPrice = standPrice + (plans[0].standardPrice3 / 1000) * (searchCount - 160000);
            plusPrice = plusPrice + (plans[0].plusPrice3 / 1000) * (searchCount - 160000);
            searchCount = 160000;
        }
        if (searchCount >= 60000) {
            standPrice = standPrice + (plans[0].standardPrice2 / 1000) * (searchCount - 60000);
            plusPrice = plusPrice + (plans[0].plusPrice2 / 1000) * (searchCount - 60000);
            searchCount = 60000;
        }
        if (searchCount >= 10000) {
            standPrice = standPrice + (plans[0].standardPrice / 1000) * (searchCount - 10000);
            plusPrice = plusPrice + (plans[0].plusPrice / 1000) * (searchCount - 10000);
        }

        setHoppysearchTotalPrice({
            standardPrice: standPrice.toFixed(2),
            plusPrice: plusPrice.toFixed(2)
        })
    }

    const algoliaPriceCalculator = () => {
        let standPrice = 0;
        let plusPrice = 0;
        if (numberOfSeraches >= 10000) {
            standPrice = (plans[1].standardPrice / 1000) * (numberOfSeraches - 10000);
            plusPrice = (plans[1].plusPrice / 1000) * (numberOfSeraches - 10000);
        }

        setAlgoliaTotalPrice({
            standardPrice: standPrice.toFixed(2),
            plusPrice: plusPrice.toFixed(2)
        })
    }

    const sitesearch360Calculator = () => {
        setSitesearch360TotalPrice({
            batmanPrice: (0.001150 * numberOfSeraches + 673.8).toFixed(2)
        })
    }

    const swiftypePriceCalculator = () => {
        let standPrice = 0;
        let plusPrice = 0;
        if (numberOfSeraches <= 50000) {
            standPrice = 79;
            plusPrice = 119;
        } else if (numberOfSeraches <= 100000) {
            standPrice = 79 + 25;
            plusPrice = 119;
        } else {
            standPrice = 79 + Math.ceil((numberOfSeraches - 100000) / 50000) * 25;
            plusPrice = 119 + Math.ceil((numberOfSeraches - 100000) / 50000) * 25;
        }

        setSwiftypeTotalPrice({
            standardPrice: standPrice.toFixed(2),
            proPrice: plusPrice.toFixed(2)
        })
    }

    const valuetext = (value) => {
        return value.toLocaleString("en-US");
    }

    const areFloatsEqual = (a, b, epsilon = 1e-10) => {
        return Math.abs(a - b) < epsilon;
    }

    const costSaving = (hoppysearchPrice, algoliaPrice) => {
        if (areFloatsEqual(algoliaPrice, hoppysearchPrice)) {
            return 0;
        } 
        const percentage = ((algoliaPrice - hoppysearchPrice) / algoliaPrice) * 100;
        return percentage.toFixed(2);
    }

    // useEffect
    useEffect(() => {
        hoppySearchPriceCalculator();
        algoliaPriceCalculator();
        swiftypePriceCalculator();
        sitesearch360Calculator();
    }, [numberOfSeraches]);

    return (
        <MainCard>
            <Grid item xs={12} container spacing={2} alignItems="center">
                <Grid item>
                    <Typography variant="body2">
                        Searches per Month:
                    </Typography>
                </Grid>
                <Grid item xs>
                    <Slider
                        getAriaValueText={valuetext}
                        valueLabelDisplay="on"
                        value={numberOfSeraches}
                        max={2000000}
                        onChange={handleSearchSlider}
                        sx={{ mt: 1 }}
                        step={1000}
                    />
                </Grid>
                <Grid item>
                    <Typography variant="h6" color="primary" sx={{ mt: 1 }}>
                        upto 2M
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12} container spacing={0} alignItems="center" sx={{ mb: 5 }}>
                <Grid item xs={2}>
                    <Typography variant="h6" display="inline">
                        Searches per Month:{" "}
                    </Typography>
                    <Typography variant="h6" sx={{ fontStyle: 'italic' }} display="inline" color="primary">
                        {numberOfSeraches.toLocaleString()}
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="h6" display="inline">
                        per Hour:{" "}
                    </Typography>
                    <Typography variant="h6" sx={{ fontStyle: 'italic' }} display="inline" color="primary">
                        {Number(
                            (numberOfSeraches / (30 * 24)).toFixed(2)
                        ).toLocaleString()}
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="h6" display="inline">
                        per Second:{" "}
                    </Typography>
                    <Typography variant="h6" sx={{ fontStyle: 'italic' }} display="inline" color="primary">
                        {Number(
                            (numberOfSeraches / (30 * 24 * 60 * 60)).toFixed(2)
                        ).toLocaleString()}
                    </Typography>
                </Grid>
            </Grid>

            <Grid container spacing={gridSpacing}>
                <Grid item xs={12} sx={{ backgroundColor: '#dcedc8', pb: 1, pr: 3, paddingTop: '10px' }}>
                    <Grid container alignItems="center" spacing={1}>
                        <Grid item sm zeroMinWidth>
                            <Typography variant="h4">
                                HoppySearch - Standard
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h4" align="right">
                                ${hoppysearchTotalPrice.standardPrice}{" "}
                            </Typography>
                            <Typography variant="body" align="right">
                                {costSaving(hoppysearchTotalPrice.standardPrice, algoliaTotalPrice.standardPrice)}% savings
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <LinearProgress
                                variant="determinate"
                                value={(100 / 3000) * hoppysearchTotalPrice.standardPrice}
                                color="primary"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ backgroundColor: '#f5f5f5', pb: 1, pr: 3 }}>
                    <Grid container alignItems="center" spacing={1}>
                        <Grid item sm zeroMinWidth>
                            <Typography variant="h4">
                                <strong>
                                    Algolia - Standard
                                </strong>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h4" align="right">
                                <strong>
                                    ${algoliaTotalPrice.standardPrice}
                                </strong>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <LinearProgress
                                variant="determinate"
                                value={(100 / 3000) * algoliaTotalPrice.standardPrice}
                                color="secondary"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ backgroundColor: '#f5f5f5', pb: 1, pr: 3 }}>
                    <Grid container alignItems="center" spacing={1}>
                        <Grid item sm zeroMinWidth>
                            <Typography variant="h4">
                                <strong>
                                    swiftype - Standard
                                </strong>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h4" align="right">
                                <strong>
                                    ${swiftypeTotalPrice.standardPrice}
                                </strong>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <LinearProgress
                                variant="determinate"
                                value={(100 / 3000) * swiftypeTotalPrice.standardPrice}
                                color="secondary"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ backgroundColor: '#f5f5f5', pb: 1, pr: 3 }}>
                    <Grid container alignItems="center" spacing={1}>
                        <Grid item sm zeroMinWidth>
                            <Typography variant="h4">
                                <strong>
                                    sitesearch360 - Batman
                                </strong>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h4" align="right">
                                <strong>
                                    ${sitesearch360TotalPrice.batmanPrice}
                                </strong>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <LinearProgress
                                variant="determinate"
                                value={(100 / 3000) * sitesearch360TotalPrice.batmanPrice}
                                color="secondary"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                {/* =================================== Pro Price code =========================== */}
                {/* <Grid item xs={12} sx={{ backgroundColor: '#dcedc8', pb: 1, pr: 3 }}>
                    <Grid container alignItems="center" spacing={1}>
                        <Grid item sm zeroMinWidth>
                            <Typography variant="h4">
                                <strong>
                                    HoppySearch - Plus
                                </strong>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h4" align="right">
                                ${hoppysearchTotalPrice.plusPrice}{" "}
                            </Typography>
                            <Typography variant="body" align="right">
                                {costSaving(hoppysearchTotalPrice.plusPrice, algoliaTotalPrice.plusPrice)}% savings
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <LinearProgress
                                variant="determinate"
                                value={(100 / 3000) * hoppysearchTotalPrice.plusPrice}
                                color="primary"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ backgroundColor: '#f5f5f5', pb: 1, pr: 3 }}>
                    <Grid container alignItems="center" spacing={1}>
                        <Grid item sm zeroMinWidth>
                            <Typography variant="h4">
                                <strong>
                                    Algolia - Premium
                                </strong>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h4" align="right">
                                <strong>
                                    ${algoliaTotalPrice.plusPrice}
                                </strong>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <LinearProgress
                                variant="determinate"
                                value={(100 / 3000) * algoliaTotalPrice.plusPrice}
                                color="secondary"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ backgroundColor: '#f5f5f5', pb: 1, pr: 3 }}>
                    <Grid container alignItems="center" spacing={1}>
                        <Grid item sm zeroMinWidth>
                            <Typography variant="h4">
                                <strong>
                                    swiftype - Pro
                                </strong>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h4" align="right">
                                <strong>
                                    ${swiftypeTotalPrice.proPrice}
                                </strong>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <LinearProgress
                                variant="determinate"
                                value={(100 / 3000) * swiftypeTotalPrice.proPrice}
                                color="secondary"
                            />
                        </Grid>
                    </Grid>
                </Grid> */}
            </Grid>
        </MainCard>
    )
};

export default PriceComparison;
