import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

const MaintenanceUnderConstruction = Loadable(lazy(() => import('views/pages/maintenance/UnderConstruction')));
const Billing = Loadable(lazy(() => import('views/pages/BillingPage/index')))
// ==============================|| MAIN ROUTING ||============================== //

const UnderMaintenanceRoutes = {
    path: '/',
    element: (
        <MainLayout />
    ),
    children: [
        {
            path: '/data-sources',
            element: <MaintenanceUnderConstruction />,
            isAuthRequired: true
        },
        {
            path: '/teams',
            element: <MaintenanceUnderConstruction />,
            isAuthRequired: true
        },
        {
            path: '/billing',
            element: <Billing />,
            isAuthRequired: true
        },
        {
            path: '/documentation',
            element: <MaintenanceUnderConstruction />,
            isAuthRequired: false
        },
        {
            path: '/chat-with-us',
            element: <MaintenanceUnderConstruction />,
            isAuthRequired: false
        }
    ]
};

export default UnderMaintenanceRoutes;
