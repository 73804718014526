export const pageInfo = [
    {
        title: 'Introduction:',
        info: [
            {
                description:
                    'Processing personal data in a secure, fair, and transparent way is extremely important to us at Hoppysearch. As part of this effort, we process personal data in accordance with the EU’s General Data Protection Regulation (“GDPR”).'
            },
            {
                description:
                    'To better protect individuals’ personal data, we are providing these terms to govern Hoppysearch’s and your handling of personal data (the “Data Processing Amendment” or “DPA”). This DPA amends and supplements your Terms of Service (“ToS”) and requires no further action on your part.'
            },
            {
                description:
                    'If you do not agree to this DPA, you may discontinue the use of the Hoppysearch service and cancel your account.'
            }
        ]
    },
    {
        title: 'Definitions:',
        info: [
            {
                description:
                    'It is important that all parties understand what data and whose data is protected under this DPA. Each party has respective obligations to protect personal data; therefore, the following definitions explain the scope of this DPA and the mutual commitments to protect personal data.'
            },
            {
                description:
                    '“Hoppysearch”, “we”, “us”, or “our” refers to the provider of the Hoppysearch website and services by Hoppysearch, Inc., (collectively referred to as the “Hoppysearch Service.”). “You” or “Customer” refers to the company or organization that signs up to use the Hoppysearch Service to manage the relationships with your consumers or service users.'
            },
            {
                description:
                    '“Party” refers to Hoppysearch and/or the customer depending on the context. “Personnel” refers to those individuals who are employed by or are under contract to perform a service on behalf of one of the parties. Personnel may have rights in their personal data (including business contact information) if they reside in the EU. It is important to be clear about how personnel’s rights are protected.'
            },
            {
                description:
                    '“GDPR” means the General Data Protection Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data.'
            },
            {
                description:
                    '“Sub-processor” is a Third-party, independent contractors, vendors and suppliers who provide specific services and products related to the Hoppysearch website and our services, such as hosting, credit card processing and fraud screening, and mailing list hosting (“third-party” or “outside contractor” shall have similar meanings).'
            },
            {
                description:
                    '“Incident” means: (a) a complaint or a request with respect to the exercise of an individual’s rights under the GDPR; (b) an investigation into or seizure of the personal data by government officials, or a specific indication that such an investigation or seizure is imminent; or (c) any breach of the security and/or confidentiality as set out in this DPA leading to the accidental or unlawful destruction, loss, alteration, unauthorized disclosure of, or access to, the personal data, or any indication of such breach having taken place or being about to take place.'
            },
            {
                description:
                    'The terms, “Data Subject”, “Personal Data”, “Member State”, “Controller”, “Processor”, and “Processing” shall have the same meaning as in the GDPR, and their cognate terms shall be construed accordingly.'
            },
            {
                description:
                    'For the sake of readability, we do not use initial capitalization of defined terms in this DPA. Defined terms are defined terms, irrespective of their format.'
            }
        ]
    },
    {
        title: '1. Undertakings regarding personal data:',
        info: [
            {
                description:
                    '1. Each party agrees that personal data shall be treated as confidential information under this DPA. In addition, each party shall at all times comply with applicable laws relating to data protection in the relevant jurisdiction with respect to each other’s personal data.'
            },
            {
                description:
                    '2. Personal Data shall remain the property of the disclosing party. Hoppysearch acknowledges that customer is the controller and maintains control over data subject’s personal data.'
            },
            {
                description:
                    '3. Hoppysearch will process customer’s personal data only to the extent strictly necessary for the purpose of providing the services in accordance with the ToS and any further written instructions from the customer that are mutually agreed upon in writing. Hoppysearch agrees that:'
            },
            {
                description:
                    'a. it will implement and maintain a reasonable and appropriate security program comprising adequate security, technical and organizational measures to protect against unauthorized, unlawful or accidental processing, use, erasure, loss or destruction of, or damage to, customer personal data;'
            },
            {
                description:
                    'b. it will not modify, alter, delete, publish or disclose any customer personal data to any third party, nor allow any third party to process such personal data on Hoppysearch’s behalf unless the third party is bound to similar confidentiality and data handling provisions;'
            },
            {
                description:
                    'c. it shall ensure that access to personal data is limited to those personnel who require such access to perform its obligations under the ToS, and its personnel engaged in the processing of personal data are informed of the confidential nature of the personal data, have received appropriate training on their responsibilities and have executed written confidentiality agreements. Hoppysearch shall ensure that such confidentiality obligations survive the termination of the personnel engagement; and'
            },
            {
                description:
                    'd. it will only process customer personal data to the extent necessary to perform its obligations under the ToS, upon written instructions of the customer (only as mutually agreed upon), and in accordance with applicable laws.'
            },
            {
                description:
                    '4. Upon termination of your Account for any reason, Hoppysearch will return or destroy Personal Data at your request and choice.'
            },
            {
                description:
                    '5. In connection with the performance of the ToS, you authorize Hoppysearch to transfer Personal Data to the United States according to “standard contractual clauses” provided by the European Commission. This transfer mechanism is deemed to be adequate for the purposes of GDPR. Hoppysearch shall not transfer any customer personal data outside of the United States or to other locations not deemed to be “adequate” under EU law without providing reasonable notice under the ToS to the customer.'
            },
            {
                description:
                    '6. The parties acknowledge that customer may from time to time be in possession of personal data relating to Hoppysearch’s personnel. Hoppysearch warrants that it has provided all necessary notifications and obtained all necessary consents, authorizations, approvals and/or agreements as required under any applicable law in order to enable: (i) the disclosure of Hoppysearch’s personal data to customer; and (ii) further processing of such Hoppysearch personal data by customer.'
            }
        ]
    },
    {
        title: '2. Undertakings regarding sub-processors:',
        info: [
            {
                description:
                    '1. The parties acknowledge that Hoppysearch may engage third-party sub-processors in connection with the obligations of the ToS. For any sub-processor with which Hoppysearch engages, we will enter into an agreement containing data protection obligations no less protective than those in this amendment and as required to protect customer’s personal data to the standard required by the GDPR.'
            },
            {
                description:
                    '2. Hoppysearch shall make available to customer the current list of sub-processors by posting that list online at: Sub Processors | Hoppysearch Cloud . Hoppysearch shall update the list with details of any addition or replacement of sub-processors at least 10 days prior to any change. If customer has a reasonable basis to object to Hoppysearch’s change in sub-processors, customer shall notify Hoppysearch promptly within 10 business days after receipt of Hoppysearch’s notice regarding sub-processor changes. Customer may object to a new sub-processor appointment or replacement, provided such objection is based on reasonable grounds related to data protection. Hoppysearch will use reasonable efforts to work in good faith with customers to find an acceptable, reasonable, alternate solution. If the parties are not able to agree to an alternate solution, customer may suspend or terminate their account.'
            }
        ]
    },
    {
        title: '3. Customer undertakings and Hoppysearch’s assistance:',
        info: [
            {
                description:
                    '1. Customer warrants that it has all necessary rights to provide to Hoppysearch the personal data for processing in connection with the provision of the Hoppysearch Services.'
            },
            {
                description:
                    '2. To the extent required by applicable law, the customer is responsible for ensuring that any data subject consents that may be necessary to this processing are obtained, and for ensuring that a record of such consents is maintained, including any consent to use personal data that is obtained from third parties. Should such consent be revoked by a data subject, customer is responsible for communicating the fact of such revocation to Hoppysearch, and Hoppysearch remains responsible for implementing any customer instruction with respect to the further processing of that personal data, or, as may be in accordance with any of Hoppysearch’s legal obligations.'
            },
            {
                description: '3. Customer understands, as a controller, that it is responsible (as between customer and Hoppysearch) for:'
            },
            {
                description:
                    'a. determining the lawfulness of any processing, performing any required data protection impact assessments, and accounting to regulators and individuals, as may be needed;'
            },
            {
                description:
                    'b. making reasonable efforts to verify parental consent when data is collected on a data subject under 16 years of age;'
            },
            {
                description:
                    'c. providing relevant privacy notices to data subjects as may be required in your jurisdiction, including notice of their rights and provide the mechanisms for individuals to exercise those rights;'
            },
            {
                description:
                    'd. responding to requests from individuals about their data and the processing of the same, including requests to have personal data altered, corrected, or erased, and providing copies of the actual data processed;'
            },
            {
                description:
                    'e. implementing your own appropriate technical and organizational measures to ensure and demonstrate processing in accord with this DPA;'
            },
            {
                description:
                    'f. notifying individuals and any relevant regulators or authorities of any incident as may be required by law in your jurisdiction.'
            },
            {
                description:
                    '4. Hoppysearch shall assist the customer by implementing appropriate technical and organizational measures, insofar as this is reasonably and commercially possible, in fulfilling customer’s obligation to respond to individuals’ requests to exercise rights under the GDPR.'
            },
            {
                description:
                    '5. Hoppysearch shall assist the customer by implementing appropriate technical and organizational measures, insofar as this is reasonably and commercially possible, to ensure compliance with articles 32 to 36 (inclusive) of the GDPR.'
            },
            {
                description:
                    '6. Hoppysearch shall, in accordance with Data Protection Laws and in response to a reasonable written request by Customer, make available to Customer such information in Hoppysearch’s possession or control related to Hoppysearch’s compliance with the obligations of data processors under Data Protection Law in relation to its Processing of Personal Data. Hoppysearch shall, upon Customer’s written request and on at least 30 days’ notice to Hoppysearch, provide Customer with all information necessary for such audit, to the extent that such information is within Hoppysearch’s control and Hoppysearch is not precluded from disclosing it by applicable law, a duty of confidentiality, or any other obligation owed to a third party.'
            }
        ]
    },
    {
        title: '4. Incident Management:',
        info: [
            {
                description:
                    '1. When either party becomes aware of an incident that impacts the processing of personal data, it shall promptly notify the other about the incident no later than seventy-two (72) hours after the discovery of the incident. In the event of such an Incident, either party shall reasonably cooperate in order to enable the other party to perform a thorough investigation into the incident, to formulate a correct response, and to take suitable further steps in respect of the incident.'
            },
            {
                description:
                    '2. Any notifications made under this section shall be made to contact@hoppysearch.com (when made to Hoppysearch) and to our point of contact with you (when made to the customer), and shall contain: (i) a description of the nature of the incident, including, where possible, the categories and approximate number of individuals concerned and the categories and approximate number of records concerned; (ii) the name and contact details of the point of contact where more information can be obtained; (iii) a description of the likely consequences of the incident; and (iv) a description of the measures taken or proposed to be taken to address the incident including, where appropriate, measures to mitigate its possible adverse effects.'
            }
        ]
    },
    {
        title: '5. Liability and Indemnity:',
        info: [
            {
                description:
                    '1. Each party indemnifies the other and holds them harmless against all claims, actions, third party claims, losses, damages and expenses incurred by the indemnified party and arising directly or indirectly out of or in connection with a breach of this DPA.'
            }
        ]
    },
    {
        title: '6. Duration and Termination:',
        info: [
            {
                description:
                    '1. This DPA shall come into effect on May 25, 2018 and shall continue until it is changed or terminated in accordance with the ToS.'
            },
            {
                description:
                    '2. Termination or expiration of this DPA shall not discharge the parties from the confidentiality obligations herein.'
            }
        ]
    }
];
