import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

const CreateIndex = Loadable(lazy(() => import('views/indices/create-index')));
const IndexDetails = Loadable(lazy(() => import('views/indices/index-details')));
const Indices = Loadable(lazy(() => import('views/indices/indices/indices')));
const Billing = Loadable(lazy(() => import('views/pages/BillingPage/index')))
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/dashboard',
            element: <DashboardDefault />,
            isAuthRequired: true
        },
        {
            path: '/indices/create-index',
            element: <CreateIndex />,
            isAuthRequired: true
        },
        {
            path: '/indices/:id',
            element: <IndexDetails />,
            isAuthRequired: true
        },
        {
            path: '/indices',
            element: <Indices />,
            isAuthRequired: true
        },
        {
            path: '/billing',
            element: <Billing />,
            isAuthRequired: true
        }
    ]
};

export default MainRoutes;
