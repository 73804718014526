import {
    ADD_INDEX_DATA,
    GET_INDEX,
    GET_ALL_INDICES,
    CREATE_API_KEY,
    GET_API_KEYS,
    GET_ALL_CORS,
    GET_INDEX_STATS,
    API_FAILED_MESSAGE,
    INDEX_STATS_FAIL_ERROR_MESSAGE
} from '../api/constant';

const indices = (
    state = {
        indices: null,
        index: null,
        createdApiKey: null,
        APIKeys: null,
        infraMeta: null,
        allCors: null,
        indexStats: null,
        indexStatsFailMessage: false,
        apiFailedErrorMessage: false
    },
    action
) => {
    switch (action.type) {
        case ADD_INDEX_DATA:
            return { ...state, index: action.data };
        case GET_INDEX:
            return { ...state, index: action.payload.index, infraMeta: action.payload.infraMeta };
        case GET_ALL_INDICES:
            return { ...state, indices: action.payload.indices };
        case CREATE_API_KEY:
            return { ...state, createdApiKey: action.payload.createdApiKey };
        case GET_API_KEYS:
            action.payload.APIKeys.sort(function (x, y) {
                return y.createdTime - x.createdTime;
            });
            return { ...state, APIKeys: action.payload.APIKeys };
        case GET_ALL_CORS:
            return { ...state, allCors: action.payload.cors };
        case API_FAILED_MESSAGE:
            return { ...state, apiFailedErrorMessage: action.payload };
        case GET_INDEX_STATS:
            return { ...state, indexStats: action.payload.indexStats };
        case INDEX_STATS_FAIL_ERROR_MESSAGE:
            return { ...state, indexStatsFailMessage: action.payload };
        default:
            return state;
    }
};
export default indices;
