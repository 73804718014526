// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconHelp, IconUsers, IconTableImport, IconStackPush, IconMessageChatbot,IconReceipt2 } from '@tabler/icons';

// constant
const icons = {
    IconHelp,
    IconUsers,
    IconTableImport,
    IconStackPush,
    IconMessageChatbot,
    IconReceipt2
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
    id: 'after-indices',
    type: 'group',
    children: [
        {
            id: 'data-sources',
            title: 'Data Sources',
            type: 'item',
            url: '/data-sources',
            icon: icons.IconStackPush,
            breadcrumbs: false
        },
        {
            id: 'teams',
            title: 'Teams',
            type: 'item',
            url: '/teams',
            icon: icons.IconUsers,
            breadcrumbs: false
        },
        {
            id: 'billing',
            title: 'Your Billing',
            type: 'item',
            url: '/billing',
            icon: icons.IconReceipt2,
            breadcrumbs: false
        },
        {
            id: 'documentation',
            title: <FormattedMessage id="documentation" />,
            type: 'item',
            url: '#',
            icon: icons.IconHelp,
            breadcrumbs: false,
            onClick: () => window.open('https://hoppysearch.readme.io/docs/home', '_blank')
        },
        {
            id: 'chat-with-us',
            title: 'Chat With Us',
            type: 'item',
            url: '#',
            icon: icons.IconMessageChatbot,
            breadcrumbs: false,
            onClick: () => {
                window.$zoho.salesiq.floatwindow.visible("show");
            }
        }
    ]
};

export default other;
