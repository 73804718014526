
let getUserObject = () => {
    return JSON.parse(localStorage.getItem("user_obj"));
}

let getIdToken = () => {
    return getUserObject().auth_tokens.id_token
}

let getRefreshToken = () => {
    return getUserObject().auth_tokens.refresh_token
}

let getAuthTokens = () => {
    return getUserObject().auth_tokens;
}

let setAuthTokens = (auth_tokens) => {
    let user = getUserObject()
    user.auth_tokens = auth_tokens;
    if (user && user.auth_tokens) localStorage.setItem("user_obj", JSON.stringify(user));
}

export {
    getUserObject,
    getIdToken,
    getRefreshToken,
    getAuthTokens,
    setAuthTokens
};