import React from 'react';
import { Box, Button, Typography, Grid, Collapse } from '@mui/material';
import { gridSpacing } from 'store/constant';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { pageInfo } from './info';

function TermsAndConditionsInfo() {
    const [expandedInfo, setExpandedInfo] = React.useState(0);

    const handleExpandeInfo = (index) => {
        setExpandedInfo(expandedInfo === index ? index : index);
    };
    return (
        <>
            {pageInfo?.map((information, index) => (
                <Box
                    key={index}
                    sx={{
                        width: '90%',
                        margin: 'auto',
                        backgroundColor: '#f9f9ff',
                        p: 4,
                        borderRadius: '3px',
                        mb: 4
                    }}
                >
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Button
                                    variant="contained"
                                    style={{
                                        borderRadius: '7px',
                                        color: 'white',
                                        minWidth: '40px',
                                        width: '40px',
                                        height: '40px',
                                        padding: '0',
                                        backgroundColor: '#a37cfc'
                                    }}
                                    onClick={() => handleExpandeInfo(index)}
                                >
                                    {expandedInfo === index ? (
                                        <KeyboardArrowUpIcon sx={{ fontSize: '2rem' }} />
                                    ) : (
                                        <KeyboardArrowDownIcon sx={{ fontSize: '2rem' }} />
                                    )}
                                </Button>
                                <Typography
                                    textAlign="left"
                                    sx={{
                                        ml: '3%',
                                        fontSize: { sm: '1.5rem' },
                                        fontFamily: 'Verdana, Arial, Helvetica, sans-serif'
                                    }}
                                >
                                    {information?.title}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Collapse in={expandedInfo === index} timeout="auto" unmountOnExit>
                                {information?.info?.map((item, ind) => (
                                    <div key={ind} style={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography
                                            style={{
                                                minWidth: '40px',
                                                width: '40px',
                                                height: '40px',
                                                padding: '0'
                                            }}
                                        >
                                            {''}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: { sm: '1.0rem' },
                                                fontFamily: 'Verdana, Arial, Helvetica, sans-serif',
                                                color: '#7c859b',
                                                ml: '3%',
                                                mb: 3
                                            }}
                                        >
                                            {item?.description}
                                        </Typography>
                                    </div>
                                ))}
                            </Collapse>
                        </Grid>
                    </Grid>
                </Box>
            ))}
        </>
    );
}
export default TermsAndConditionsInfo;
